import React from 'react';


function Teaching({teaching, syllabus, research}) {


    return (
        <div>
            <h3>Summary</h3>
            <p style={{textAlign:'justify'}}>&nbsp;&nbsp;&nbsp;{teaching.map(teaching=>teaching.summary)}</p>
            <br/>
            <h3>Syllabus</h3>
            <ul>
                {syllabus.map((syllabus, index) =>
                    <li className="ml-5" key={index}>
                        <a href={syllabus.link} target="_blank" rel="noopener noreferrer">{syllabus.syllabi}</a>
                    </li>
                    )}
            </ul>
            <br/>
            <h3>Area of Research</h3>
                {
                   research.map((research, index)=><ul key={index} style={{textAlign:'justify'}}><b>{research.title}</b><li className="ml-5">{research.area_of_research}</li></ul>) 
                }
            


        </div>
    )
}

export default Teaching
