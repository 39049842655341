import React from 'react';
import './SASS/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from 'react-router-dom';
// import Main from './componennt/Main/Main';
import MainPage from './componennt/Main/MainPage';
// import Dashbord from './componennt/admin/dashbord';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

function App() {

  AOS.init();
  return (
    <div>
      <BrowserRouter>
       <MainPage/>
       {/* <Route exact path="/Dashbord" component={Dashbord}></Route> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
