import React from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import Data from './Data';

function Organized_Event() {
    const [loading, setloading]= React.useState(true)
    const [organized_event_webina, setorganized_event_webina] = React.useState([])
    const [organized_event_course_convener, setorganized_event_course_convener] = React.useState([])
    const [organized_event_conference_convener, setorganized_event_conference_convener] = React.useState([])
    

    React.useEffect(()=>{
        const getData = async()=>{

            const resp1 = await axios.get(process.env.REACT_APP_organized_event_webinar);
            const resp2 = await axios.get(process.env.REACT_APP_organized_event_course_convener);
            const resp3 = await axios.get(process.env.REACT_APP_organized_event_conference_convener);

            setloading(false);
            setorganized_event_webina(resp1.data)
            setorganized_event_course_convener(resp2.data)
            setorganized_event_conference_convener(resp3.data)

        }

        getData()
    })


    return (
        <div>
            <h3 className="primary-heading">Organized Events</h3>
            <hr/>
            {loading ?<Loading/> : <Data organized_event_webina={organized_event_webina} organized_event_course_convener={organized_event_course_convener} organized_event_conference_convener={organized_event_conference_convener} />}
            
        </div>
    )
}

export default Organized_Event
