import React from 'react';

function Education() {
    const Education = [
        {course_detail:'Ph.D. Computer Science - 2013',address:'University of Madras, India.'},
        {course_detail:'M.Phil. Computer Science - 2000.',address:'Mother Teresa Women’s University, India.'},
        {course_detail:'M.C.A. - 1990.',address:'Alagappa University, India.'},
        {course_detail:'B.Sc. Physics -1987.',address:'Madurai Kamaraj University, India.'}
    ]

    return (
        <div data-aos="zoom-in-up">
            <h3 className="primary-heading">Education</h3>
            <hr/>
            <ul style={{padding:'0 40px'}}>
                {Education.map((response, index) =>(
                    <li key={index} style={{marginBottom:"15px"}}>{response.course_detail}
                    <ul>
                        <li style={{listStyle:'none'}}>{response.address}</li>
                    </ul>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Education
