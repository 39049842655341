import React from 'react'

function ProfessionalActivities({professional_activities_webinar, professional_activities_chairperson}) {

    function dateformate(datas){
       const d =  new Date(datas)
       return d.toString().slice(4,15)
    }

    
    return (
        <div data-aos="zoom-in-right">
           <br/>
            <h4>Chief Guest/Chair Person</h4>
            <br/>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">Date</th>
                <th scope="col">Details</th>
                </tr>
            </thead>
            <tbody>
                {professional_activities_chairperson.map((resp,index)=><tr key={index}><th style={{width:'140px'}}>{dateformate(resp.date)}</th><td>{resp.details}</td></tr>)}
            </tbody>
            </table>

            <h4>Resource Person in FDP/ Special Lecture/ Webinar</h4>
            <br/>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">Date</th>
                <th scope="col">Details</th>
                </tr>
            </thead>
            <tbody>
                {professional_activities_webinar.map((resp,index)=><tr key={index}><th style={{width:'140px'}}>{dateformate(resp.date)}</th><td>{resp.details}</td></tr>)}
            </tbody>
            </table>
        </div>
    )
}

export default ProfessionalActivities
