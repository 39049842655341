import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Document from './Document.pdf'
import photo from '../../../image/photo.jpg';
import {SiResearchgate} from 'react-icons/si';
import {FaLinkedinIn} from 'react-icons/fa';
import {IoIosSchool} from 'react-icons/io'

function Home() {
    return (
        <>
            <Grid container className="bio">
                <Grid item md={3} sm={6}>
                <img className="photo" src={photo} alt="Dr. Mrs. PL. Chithra" data-aos="zoom-in-down"/>
                </Grid>
                <Grid item md={9} sm={6}>
                    <h2 className="main-heading">Dr. Mrs. PL. Chithra</h2>
                    <h3 className="Second-main-heading">Professor</h3>
                    <hr/>
                    <p className="paragraph" data-aos="fade-left">
                    Dr.PL.Chithra is working as a professor in the Department of Computer Science,
                    University of Madras. Main areas of research include 3D Digital Image Processing,
                    Pattern Recognition, Artificial Intelligence, Signal Processing, Network Security, and
                    Video Processing. She received Ph.D. in titled Multiresolution based image codecs for
                    Lossy/Lossless compression, from the University of Madras and M.C.A. degree from the
                    Alagappa University, Tamil Nadu, India. She is passionate on teaching, imparting,
                    training, motivating and helping of young minds with knowledge and good discipline.
                    She has 30 years of teaching and 20 years of research experience in Computer Science.
                    Serving an Organizing Chair, Program Chair and Program Committees of several
                    national and International conferences. She has awarded UGC FIP program for two years,
                    conducted and act as a resource person for several refresher courses. She published more
                    than 96 papers in National and International reputed journals with 70 citations and 5 hindex. Serving as one of the Computer Science staff selection committee members, BOS
                    member and inspection committee member for various affiliated colleges of University of
                    Madras since 2000.

                    <br/><br/>
                    <a href="https://orcid.org/0000-0001-7450-4170" target="_blank" rel="noopener noreferrer"><b>ORCID ID:</b> 0000-0001-7450-4170</a>
                    </p>
                    <div className="icons-list">
                        <a href="https://www.researchgate.net/profile/Chithra_Pl" target="_blank" rel="noopener noreferrer"><SiResearchgate className="icon"/></a>
                        <a href="https://www.linkedin.com/in/pl-chithra-b065971b3/?originalSubdomain=in" target="_blank" rel="noopener noreferrer"><FaLinkedinIn className="icon"/></a>
                        <a href="https://scholar.google.com/citations?user=9Q3UEqAAAAAJ&amp;hl=en" target="_blank" rel="noopener noreferrer"><IoIosSchool className="icon"/></a>
                    </div>
                    

                    <Button variant="outlined" color="primary" href={Document} target="_blank" rel="noopener noreferrer">
                        Curriculum Vitae
                    </Button>
                    <div>
                        
                        
                        
                    </div>
                    
                </Grid>
            </Grid>
        </>
    )
}

export default Home
