import React from 'react';

function Data({achievements}) {
    return (
        <div data-aos="zoom-in-right" style={{textAlign:'justify'}}>
            <ul>
            {achievements.map((achievements, index)=><li key={index} className="ml-5">{achievements.achievements}</li>)} 
            </ul>
        </div>
    )
}

export default Data
