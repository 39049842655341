import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
// import react router dom
import {Link} from "react-router-dom";

// import list fuctionality
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// import icons
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import PublicIcon from '@material-ui/icons/Public';
import BookIcon from '@material-ui/icons/Book';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import RowingIcon from '@material-ui/icons/Rowing';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: '#3f51b5',
      color: 'white'
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

function SidebarData() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };
    return (
        <div>
            <List className={classes.root}> 
            {/* Home */}

            <Link to="/" style={{ textDecoration: 'none'}}>
            <ListItem button >
              <ListItemIcon>
                <HomeIcon style={{color:'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Home" style={{color:'white'}}/>
            </ListItem>
            </Link>

            <Link to="/Education" style={{ textDecoration: 'none'}}>
            <ListItem button>
              <ListItemIcon>
                <SchoolIcon style={{color:'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Education" style={{color:'white'}} />
            </ListItem>
            </Link>

            <Link to="/Teaching_Research" style={{ textDecoration: 'none'}}>
              <ListItem button>
              <ListItemIcon>
                <SpeakerNotesIcon style={{color:'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Teaching and Research" style={{color:'white'}} />
            </ListItem>
            </Link>

            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <PublicIcon style={{color:'white'}}/>
              </ListItemIcon>
              <ListItemText primary="Publications" style={{color:'white'}}/>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/* list colapse one */}
            <Collapse in={open} timeout="auto" unmountOnExit>

              <Link to="/Journal_Publications" style={{ textDecoration: 'none'}}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                    <BookIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Journal Publications" style={{color:'white'}}/>
                  </ListItem>
                </List>
              </Link>

              <Link to="/PresentedConferences" style={{ textDecoration: 'none'}}>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon >
                    <GroupAddIcon style={{color:'white'}} />
                    </ListItemIcon>
                    <ListItemText primary="Presented Conferences" style={{color:'white'}}/>
                  </ListItem>
                </List>
              </Link>
            </Collapse>

            <Link to="/Professional_Activities" style={{ textDecoration: 'none'}}>
            <ListItem button>
              <ListItemIcon>
                <RowingIcon style={{color:'white'}}/>
              </ListItemIcon >
              <ListItemText primary="Professional Activities" style={{color:'white'}}/>
            </ListItem>
            </Link>

            <Link to="/Organized_Event" style={{ textDecoration: 'none'}}>
              <ListItem button>
                <ListItemIcon>
                  <EventIcon style={{color:'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Organized Event" style={{color:'white'}}/>
              </ListItem>
            </Link>
            
            <Link to="/Achievements" style={{ textDecoration: 'none'}}>
              <ListItem button>
                <ListItemIcon>
                  <EmojiEventsIcon style={{color:'white'}}/>
                </ListItemIcon >
                <ListItemText primary="Achievements" style={{color:'white'}} />
              </ListItem>
            </Link>
            

            <Link to="Contact" style={{ textDecoration: 'none'}}>
              <ListItem button>
                <ListItemIcon>
                  <ContactPhoneIcon style={{color:'white'}}/>
                </ListItemIcon>
                <ListItemText primary="Contact" style={{color:'white'}}/>
              </ListItem>
            </Link>

          </List>
        </div>
    )
}

export default SidebarData
