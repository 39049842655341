import React from 'react'

function Publications() {
    return (
        <div>
            Publication
        </div>
    )
}

export default Publications
