import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SidebarData from './SidebarData';

// import react router dom
import {Switch,Route} from "react-router-dom";

// import Pages for website

import Home from '../Page/Home/Home';
import Education from '../Page/Education/Education';
import Teaching_Research from '../Page/Teaching_and_Research/Teaching_Research';
import Publications from '../Page/Publications/Publications';
import Journal_Publications from '../Page/Publications/Journal_Publication/Journal_Publication';
import PresentedConferences from '../Page/Publications/Conference/Conference';
import Professional_Activities from '../Page/Professional_Activities/Professional_Activities'
import Organized_Event from '../Page/Organized_Event/Organized_Event';
import Achievements from '../Page/Achivement/Achievements';
import Contact from '../Page/Contact/Contact';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#3f51b5',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MainPage(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };




  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className="nav-header">
            <p >Dr. Mrs. PL. Chithra</p>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {/* Mobile view */}
            <SidebarData/>
            
          </Drawer>
        </Hidden>
        
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <SidebarData/>
            
          </Drawer>
        </Hidden>
        
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/Education" component={Education}></Route>
          <Route exact path="/Teaching_Research" component={Teaching_Research}></Route>
          <Route exact path="/Publications" component={Publications}></Route>
          <Route exact path="/Journal_Publications" component={Journal_Publications}></Route>
          <Route exact path="/PresentedConferences" component={PresentedConferences}></Route>
          <Route exact path="/Professional_Activities" component={Professional_Activities}></Route>
          <Route exact path="/Organized_Event" component={Organized_Event}></Route>
          <Route exact path="/Achievements" component={Achievements}></Route>
          <Route exact path="/Contact" component={Contact}></Route>
        </Switch>
      </main>
    </div>
  );
}

MainPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


export default MainPage
