import React from 'react';
import axios from 'axios';
import Loading from '../Loading/Loading';
import Teaching from './Teaching';


function Teaching_Research() {
    const [teaching, seteaching] = React.useState([]);
    const [syllabus, setsyllabus] = React.useState([]);
    const [research, setresearch] = React.useState([]);
    const [loading, setloding] = React.useState(true);

    React.useEffect(()=>{
            const getData = async()=>{
            const resp_one = await axios.get(process.env.React_APP_TEACHING)
            const resp_two = await axios.get(process.env.REACT_APP_SYLLABUSES)
            const resp_three = await axios.get(process.env.REACT_APP_RESERCHPUBLICATION)

            setloding(false)
            seteaching(resp_one.data)
            setsyllabus(resp_two.data)
            setresearch(resp_three.data)
            
            }  

            getData()
    
    },[])

    return (
        <div>
            <h3 className="primary-heading">Teaching and Research</h3>
            <hr/>
            {loading ?<Loading/> : <Teaching teaching={teaching} syllabus={syllabus} research={research}/>}
            
        </div>
    )
}

export default Teaching_Research
