import React from 'react';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import JournalData_accordion from './Accordion/JournalData_accordion';
import Loading from '../../Loading/Loading';
import {GoSearch} from 'react-icons/go';


function Journal_Publication() {
    const [journalSearch, setjournalSearch] = React.useState([])
    const [journalData, SetjournalData] = React.useState([])
    const [loading, setloading] = React.useState(true)

    React.useEffect(()=>{
        const getJournalData = async()=>{
          const resp = await axios.get(process.env.REACT_APP_journal_publication)
            setloading(false)
            SetjournalData(resp.data)
        }
    
        getJournalData()
    },[])
    


// Search field for journal
    const onChange = (e)=>{
        // e.preventDefault()   
        setjournalSearch(e.target.value)   
    }
    
    const filterJournal = journalData.filter(journal => journal.title.toString().toLowerCase().includes(journalSearch.toString().toLowerCase()))

    return (
        <div>
            <h3 className="primary-heading">Journal Publications</h3>
            <hr/>
            
            <div className="input">
            <TextField id="standard-basic" label="Search" name="search" onChange={onChange} /> <GoSearch className="search-icon"/>
            </div>

            <div>
                {loading ? <Loading/>: <JournalData_accordion filterJournal={filterJournal}/>}
           
            </div>

        </div>
    )
}

export default Journal_Publication
