import React from 'react';
import axios from 'axios';
import ProfessionalActivities from './ProfessionalActivities';
import Loading from '../Loading/Loading';

function Professional_Activities() {
    const [loading, setloading]= React.useState(true);
    const [professional_activities_webinar, setprofessional_activities_webinar] = React.useState([])
    const [professional_activities_chairperson, setprofessional_activities_chairperson] = React.useState([])

    React.useEffect(()=>{
        const getData = async()=>{

            const resp1 = await axios.get(process.env.REACT_APP_professional_activities_webinar);
            const resp2 = await axios.get(process.env.REACT_APP_professional_activities_chairperson);
            setloading(false);
            setprofessional_activities_webinar(resp1.data)
            setprofessional_activities_chairperson(resp2.data)

        }
        getData()
    }
    )

        
        
    return (
        <div>
            <h3 className="primary-heading">Professional Activities</h3>
            <hr/>
            {loading ?<Loading/> : <ProfessionalActivities professional_activities_webinar={professional_activities_webinar} professional_activities_chairperson={professional_activities_chairperson}/>}
            
        </div>
    )
}


export default Professional_Activities
