import React from 'react'

function Data({organized_event_webina,organized_event_course_convener,organized_event_conference_convener}) {
    function dateformate(datas){
        const d =  new Date(datas)
        return d.toString().slice(4,15)
     }
    return (
        <div data-aos="zoom-in-right">
            <br/>
            <h4>FDP/Special Lecture/ Webinar/Workshop Convener</h4>
            <br/>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">Date</th>
                <th scope="col">Event Details</th>
                </tr>
            </thead>
            <tbody>
                {organized_event_webina.map((resp,index)=><tr key={index}><th style={{width:'140px'}}>{dateformate(resp.date)}</th><td>{resp.details}</td></tr>)}
            </tbody>
            </table>

            <h4>Refresher Course Convener</h4>
            <br/>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">Date</th>
                <th scope="col">Event Details</th>
                </tr>
            </thead>
            <tbody>
                {organized_event_course_convener.map((resp,index)=><tr key={index}><th style={{width:'140px'}}>{dateformate(resp.date)}</th><td>{resp.details}</td></tr>)}
            </tbody>
            </table>

            <h4>Conference Convener</h4>
            <br/>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">Date</th>
                <th scope="col">Event Details</th>
                </tr>
            </thead>
            <tbody>
                {organized_event_conference_convener.map((resp,index)=><tr key={index}><th style={{width:'140px'}}>{dateformate(resp.date)}</th><td>{resp.details}</td></tr>)}
            </tbody>
            </table>
        </div>
    )
}

export default Data
