import React from 'react';
import Data from './Data';
import axios from 'axios';
import Loading from '../Loading/Loading';

function Achievements() {
    const [achievements, setachievements] = React.useState([]);
    const [loading, setloding] = React.useState(true);

    React.useEffect(()=>{
        const getData = async()=>{
        const resp = await axios.get(process.env.REACT_APP_achievements)

        setloding(false)
        setachievements (resp.data)
        
        }  

        getData()

},[])
    
    return (
        <div>
            <h3 className="primary-heading">Achievements</h3>
            <hr/>
            {loading ?<Loading/> : <Data achievements={achievements}/>}
        </div>
    )
}

export default Achievements
