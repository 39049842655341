import React from 'react';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import Data from './Data.jsx';


function Conference() {
    const [getconference, setconference] = React.useState([]);
    const [loading, setloding] = React.useState(true);

    React.useEffect(()=>{
        const getconferenceData = async()=>{
            const resp = await axios.get(process.env.REACT_APP_conference)
            setloding(false)
            setconference(resp.data)
        }
    
        getconferenceData()
    },[])

    return (
        <div>
            <h3 className="primary-heading">Presented Conferences</h3>
            <hr/>
            {loading ?<Loading/> : <Data getconference={getconference}/>}
        </div>
    )
}

export default Conference
