import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';


const onSubmit = async(e)=>{
    e.preventDefault()
    const ContactData = {
        name: e.target.elements.name.value,
        email: e.target.elements.email.value,
        message: e.target.elements.message.value,
    }
    await axios.post(process.env.REACT_APP_contact,ContactData)
        .then(()=>alert("Record saved!!"))
        .catch(err => console.log(err))
}


function Contact() {
   
    return (
        <div>
            <Typography gutterBottom variant="h4" component="h4">
            Contact
            </Typography>
            <hr/>
            <Grid container >
                <Grid item md={6} data-aos="zoom-in">
                <form className="mr-3" onSubmit={onSubmit}>
                <div className="form-group mb-3">
                            <label>Name</label>
                            <input type="text" className="form-control" name="name" required/>
                        </div>

                        <div className="form-group mb-3">
                            <label>Email</label>
                            <input type="email" className="form-control" name="email" required/>
                        </div>

                        <div className="form-group mb-3">
                            <label>Message</label>
                            <textarea className="form-control" rows="3" name="message" required></textarea>
                        </div>
                        <button type="submit" className="btn btn-outline-primary" >submit</button>
                </form>
                </Grid>
                <Grid item md={6} data-aos="zoom-in-up">
                <Typography gutterBottom variant="h5" component="h3">
                Address:
                </Typography>

                <Typography variant="h6" color="textSecondary" component="h6">
                    Department of Computer Science,<br/>
                    University of Madras,<br/>
                    Guindy Campus,<br/>
                    Chennai - 600025.<br/>
                    Office: <a href="mailto:chitra.cs@unom.ac.in">chitra.cs@unom.ac.in</a><br/>
                    Office: <a href="mailto:chitrasp2001@yahoo.com">chitrasp2001@yahoo.com</a><br/>
                    Office: <a href="mailto:aswinchitra@gmail.com">aswinchitra@gmail.com</a><br/>
                    Office: 044-2220 2902
                </Typography>

                </Grid>
            </Grid>
        </div>
    )
}

export default Contact
