import React from 'react'

function Data({getconference}) {
    return (
        <div>
            <table className="table table-bordered" style={{textAlign:'justify'}}>
            <thead className="thead-light">
                <tr style={{textAlign:"center"}}>
                <th scope="col">S.No</th>
                <th scope="col">Title of the Article / name (s) of the author (s) , Conference Details</th>
                </tr>
            </thead>
            <tbody>
            {
                getconference.map((conference, index) =>{
                    return(
                    <tr key={index} data-aos="zoom-in-right">
                        <th scope="row">{conference.si_no}</th>
                        <td><h6 style={{textAlign:'justify',lineHeight:'1.7'}}>{conference.conference_detail}</h6></td>
                    </tr>
                    )
                })
            }
            </tbody>
            
            </table>
        </div>
    )
}

export default Data
