import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    padding: theme.spacing(1),
  },
}));

function JournalData_accordion({filterJournal}) {
  
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onClick = async()=>{
    const Download = {
      download:'downloaded'
    }

    await axios.post(process.env.REACT_APP_DOWNLOADCOUNT,Download)
        
  }
    return (
        <div className={classes.root} data-aos="zoom-in">
          {filterJournal.map((journal, index)=>{
            return(
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div>
                  <h6 >{journal.title}</h6>
                  <p style={{color:'#f39c12'}}>{journal.author}</p>
                  <p style={{color:'#1abc9c'}}>{journal.year}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <h6><b>Journal: </b>{journal.journal}</h6>
                    <h6><b>ISSN No: </b>{journal.issn}</h6>
                    <h6><b>Vol No: </b>{journal.vol}</h6>
                    <h6><b>Issue No: </b>{journal.issu}</h6>
                    <h6><b>Page No: </b>{journal.page}</h6>
                    <h6><b>Indexing: </b>{journal.indexing}</h6>
                    <Button variant="outlined" color="primary" href={journal.link} target="_blank" rel="noopener noreferrer" style={{marginRight:'3px'}} >
                        DOI
                    </Button>
                    <Button variant="outlined" color="primary" href={journal.download} target="_blank" rel="noopener noreferrer" onClick={onClick}>
                        Download
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
            
        </div>
    )
}

export default JournalData_accordion
